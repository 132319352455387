import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Menu from "./menu"
import "../styles/components/_footer.scss"

export default () => (
  <StaticQuery
    query={graphql`
      query FooterQuery {
        allContentfulFooter {
          nodes {
            copyright
            logo {
              description
              file {
                url
              }
            }
            socialMedia {
              icon {
                description
                file {
                  url
                }
              }
              url
            }
            productsMenuName
            productsMenu {
              label
              url
            }
            companyMenuName
            companyMenu {
              label
              url
            }
          }
        }
      }
    `}
    render={data => (
      <footer>
        <div className="footer-info">
          <img
            src={data.allContentfulFooter.nodes[0].logo.file.url}
            alt={data.allContentfulFooter.nodes[0].logo.description}
            className="logo"
          />
          <ul className="social-media">
            {data.allContentfulFooter.nodes[0].socialMedia.map(
              (socialItem, n) => {
                return (
                  <li key={n}>
                    <a
                      href={socialItem.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={socialItem.icon.file.url}
                        alt={socialItem.icon.description}
                      />
                    </a>
                  </li>
                )
              }
            )}
          </ul>
          <div className="copyright">{`© ${new Date().getFullYear()} ${
            data.allContentfulFooter.nodes[0].copyright
          }`}</div>
        </div>
        <div className="menus">
          <Menu
            menuName={data.allContentfulFooter.nodes[0].productsMenuName}
            menuItems={data.allContentfulFooter.nodes[0].productsMenu}
          />
          <Menu
            menuName={data.allContentfulFooter.nodes[0].companyMenuName}
            menuItems={data.allContentfulFooter.nodes[0].companyMenu}
          />
        </div>
      </footer>
    )}
  />
)
